import useFetch from "./../hooks/useFetch";

const endPoint = "students";

export async function PostEvaluateMasterApi({ token, data }) {
  const apiCall = await useFetch().post(`${endPoint}/evaluate`, data, {
    headers: {
      Authorization: `Bearer ` + token,
    },
  });

  return apiCall;
}

export async function GetEvaluateMasterApi({ token, id }) {
  const apiCall = await useFetch().get(`${endPoint}/evaluate/${id}`, {
    headers: {
      Authorization: `Bearer ` + token,
    },
  });

  return apiCall;
}

export async function PutEvaluateMasterApi({ token, data, id }) {
  const apiCall = await useFetch().put(`${endPoint}/evaluate/${id}`, data, {
    headers: {
      Authorization: `Bearer ` + token,
    },
  });

  return apiCall;
}
