import useFetch from "./../hooks/useFetch";

const endPoint = "students";

export async function GetStudentFinalReport({ token, id }) {
  const apiCall = await useFetch().get(`${endPoint}/forms/${id}/final_report`, {
    headers: {
      Authorization: `Bearer ` + token,
    },
  });

  return apiCall;
}
