import React, { useState } from "react";

//component
import DetailStudentModal from "../detailStudentModal";
import RejectStudentModal from "../rejectStudentModal";

//svg
import { ReactComponent as Tick } from "./../../../../assets/icons/svg/tick-square.svg";
import { ReactComponent as Close } from "./../../../../assets/icons/svg/x-square.svg";
import ConfirmStudentModal from "../confirmStudentModal";

const RequestItem = ({ data, index }) => {
  const [isShowDetailStudent, setIsShowDetailStudent] = useState(false);
  const [isShowDeleteStudent, setIsShowDeleteStudent] = useState(false);
  const [isShowConfirmStudent, setIsShowConfirmStudent] = useState(false);

  return (
    <>
      <tr className="grid grid-cols-12 w-full">
        <td className="col-span-1">{index + 1}</td>
        <td className="col-span-2">{data.first_name}</td>
        <td className="col-span-2">{data.last_name}</td>
        <td className="col-span-2">{data.student_number}</td>
        <td className="col-span-2">{data.entrance_year}</td>
        <td className="col-span-2 flex items-center justify-evenly">
          <button
            onClick={() => setIsShowConfirmStudent(true)}
            className="w-10 text-green-700 hover:text-green-900 duration-200"
          >
            <Tick />
          </button>
          <button
            onClick={() => setIsShowDeleteStudent(true)}
            className="w-10 text-red-700 hover:text-red-900 duration-200"
          >
            <Close />
          </button>
        </td>
        <td className="col-span-1 text-[#D5A419]">
          <button onClick={() => setIsShowDetailStudent(true)}>جزئیات</button>
        </td>
      </tr>

      {isShowDetailStudent && (
        <DetailStudentModal
          id={data.id}
          isShowModal={isShowDetailStudent}
          onCloseModalHandler={() => setIsShowDetailStudent(false)}
        />
      )}

      {isShowDeleteStudent && (
        <RejectStudentModal
          firstName={data.first_name}
          lastName={data.last_name}
          studentNumber={data.student_number}
          id={data.id}
          isShowModal={isShowDeleteStudent}
          onCloseModalHandler={() => setIsShowDeleteStudent(false)}
        />
      )}

      {isShowConfirmStudent && (
        <ConfirmStudentModal
          firstName={data.first_name}
          lastName={data.last_name}
          studentNumber={data.student_number}
          id={data.id}
          isShowModal={isShowConfirmStudent}
          onCloseModalHandler={() => setIsShowConfirmStudent(false)}
        />
      )}
    </>
  );
};

export default RequestItem;
