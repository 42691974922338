import { useEffect, useState } from "react";

//react router dom
import { useNavigate } from "react-router-dom";

//cookies
import { useCookies } from "react-cookie";

//component
import ModalWrapper from "../../../common/modalWrapper";

//service
import { ChangeStudentNumber } from "../../../../service/students";

//svg
import { ReactComponent as AlertSvg } from "./../../../../assets/icons/svg/alert.svg";

//utils
import Spinner from "../../../utils/spinner";

import { toast } from "react-toastify";

export default function ChangeStudentModal({
  onCloseModalHandler,
  isShowModal,
  prevStudentNumber,
}) {
  //navigate
  const navigate = useNavigate();

  //cookies
  const [{ token }] = useCookies(["token"]);

  //data
  const [isLoading, setIsLoading] = useState(false);
  const [studentNumber, setStudentNumber] = useState("");
  const [errorStudentNumber, setErrorStudentNumber] = useState("");

  //add prevStudent number to the user input student number
  useEffect(() => {
    setStudentNumber(prevStudentNumber);
  }, [prevStudentNumber]);

  const httpConfirmStudent = async () => {
    //check if is loading => dont response to backend
    if (isLoading) return;

    //check if we have error
    if (errorStudentNumber !== "") {
      //there is error in user student numebr , return with out give request to backend
      return;
    }

    setIsLoading(true);
    try {
      const response = await ChangeStudentNumber({
        token,
        newStudentNumber: studentNumber,
      });

      if (response.status === 200) {
        //reload this page
        navigate(0);
      } else {
        toast.error("تغییر ظرفیت دانشجویان با مشکل مواجه شد");

        console.log("status code : ", response.status);
      }
    } catch (error) {
      console.log("error in change student number : ", error);
    }
    setIsLoading(false);
  };

  //check the value is set in the studen number
  useEffect(() => {
    console.log("this is student number : ", studentNumber);
    if (studentNumber === "") {
      setErrorStudentNumber("مقدار وارد شده صحیح نمیباشد");
    } else if (+studentNumber < 0) {
      setErrorStudentNumber("مقدار ظرفیت باید بیشتر از صفر باشد");
    } else {
      setErrorStudentNumber("");
    }
  }, [studentNumber]);

  return (
    <ModalWrapper
      isShowedModal={isShowModal}
      onCloseModal={onCloseModalHandler}
      //   modalClass={"w-[320px]"}
    >
      <div className="">
        <p className="mb-3 font-semibold text-right">
          ظرفیت دانشجویان شما "{prevStudentNumber}" اگر مایل به تغییر ظرفیت
          دانشجویان هستید مقدار جدید را وارد کنید
        </p>
        <p className="flex items-center justify-start gap-1 mb-7 text-xs text-right text-red-700">
          <AlertSvg className="w-5" />
          ظرفیت دانشجویانی که وارد میکنید باید بیشتر از مقدار دانشجویانی باشد که
          درحال حاضر تایید کرده اید
        </p>

        <div className="flex items-center justify-center flex-col gap-2 mb-7">
          <input
            className="outline-none border-2 border-gray-500 px-2 py-1 rounded-md"
            type="number"
            value={studentNumber}
            onChange={(e) => setStudentNumber(e.target.value)}
          />
          <span className="text-xs text-red-700">{errorStudentNumber}</span>
        </div>

        <div className="w-full flex items-center justify-end gap-5">
          <button
            onClick={httpConfirmStudent}
            className="px-7 py-1 text-white bg-green-700 border-2 border-green-700 hover:text-green-700 hover:bg-white duration-200 rounded-md"
          >
            {isLoading ? (
              <Spinner
                size="7"
                fillColor="fill-white"
                textColor="text-green-700"
              />
            ) : (
              "تایید"
            )}
          </button>
          <button
            onClick={onCloseModalHandler}
            className="px-7 py-1 text-white bg-yellow-700 border-2 border-yellow-700 hover:text-yellow-700 hover:bg-white duration-200 rounded-md"
          >
            انصراف
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}
