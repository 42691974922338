import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import {
  GetEvaluateMasterApi,
  PostEvaluateMasterApi,
  PutEvaluateMasterApi,
} from "../../../../service/evaluate-master";
import { toast } from "react-toastify";
const FormMasterValidation = ({ token, studentId }) => {
  const navigate = useNavigate();
  const [isUpdated, setIsUpdated] = useState(false);
  const [dataSchema, setDataSchema] = useState({
    internship_visit: "",
    report_validation: "",
    examination_score: "",
    final_evaluation: "",
  });
  const [loadingBtn, setLoadingBtn] = useState(false);

  useEffect(() => {
    onGetMasterEvaluation();
  }, []);

  const onGetMasterEvaluation = async () => {
    try {
      const response = await GetEvaluateMasterApi({ token, id: studentId });

      if (response.status === 200) {
        if (Object.keys(response.data).length > 0) setIsUpdated(true);
        setDataSchema({
          ...response.data,
        });
      }
    } catch (error) {
      console.log("error in get master evaluation : ", error);
    }
  };

  const onSubmitMasterEvaluationHandler = async () => {
    setLoadingBtn(true);
    try {
      const response = await PostEvaluateMasterApi({
        token,
        data: {
          evaluate: { ...dataSchema },
          student_id: studentId,
        },
      });

      if (response.status === 200) {
        toast.success("با موفقیت نمره ثبت شد");
        navigate(`/internships/${studentId}`);
      }
    } catch (error) {
      console.log("error in submit master evaluation : ", error);
    }

    setLoadingBtn(false);
  };

  const onEditMasterEvaluationHandler = async () => {
    setLoadingBtn(true);
    try {
      const response = await PutEvaluateMasterApi({
        token,
        data: {
          evaluate: { ...dataSchema },
        },
        id: studentId,
      });

      if (response.status === 200) {
        toast.success("با موفقیت نمره ثبت شد");
        navigate(`/internships/${studentId}`);
      }
    } catch (error) {
      console.log("error in submit master evaluation : ", error);
    }

    setLoadingBtn(false);
  };

  const onChangeDataHandler = (target, value) => {
    if (/^\d*$/.test(value)) {
      if (value <= 20) {
        setDataSchema((prevState) => ({
          ...prevState,
          [target]: value,
        }));
      }
    }
  };

  return (
    <div className="flex flex-col gap-7 w-full">
      <div className="grid sm:grid-cols-2 gap-5 w-full">
        <div className="flex flex-col items-start justify-start gap-2 w-full">
          <label className="text-sm font-medium" htmlFor="internship_visit">
            میانگین ارزشیابی بازدیدی های استاد کارآموزی
          </label>
          <input
            onChange={(e) =>
              onChangeDataHandler("internship_visit", e.target.value)
            }
            value={dataSchema.internship_visit}
            className="border rounded-md outline-none px-2 py-1 w-full"
            id="internship_visit"
            type="text"
          />
        </div>
        <div className="flex flex-col items-start justify-start gap-2 w-full">
          <label className="text-sm font-medium" htmlFor="report_validation">
            ارزشیابی گزارش های کارآموزی دانشجو توسط استاد
          </label>
          <input
            onChange={(e) =>
              onChangeDataHandler("report_validation", e.target.value)
            }
            value={dataSchema.report_validation}
            className="border rounded-md outline-none px-2 py-1 w-full"
            id="report_validation"
            type="text"
          />
        </div>
        <div className="flex flex-col items-start justify-start gap-2 w-full">
          <label className="text-sm font-medium" htmlFor="examination_score">
            امتحان و دفاع دانشجو - در جلسه حضوری
          </label>
          <input
            onChange={(e) =>
              onChangeDataHandler("examination_score", e.target.value)
            }
            value={dataSchema.examination_score}
            className="border rounded-md outline-none px-2 py-1 w-full"
            id="examination_score"
            type="text"
          />
        </div>
        <div className="flex flex-col items-start justify-start gap-2 w-full">
          <label className="text-sm font-medium" htmlFor="final_evaluation">
            ارزیابی نهایی{" "}
          </label>
          <input
            onChange={(e) =>
              onChangeDataHandler("final_evaluation", e.target.value)
            }
            value={dataSchema.final_evaluation}
            className="border rounded-md outline-none px-2 py-1 w-full"
            id="final_evaluation"
            type="text"
          />
        </div>
      </div>
      <Button
        onClick={
          isUpdated
            ? onEditMasterEvaluationHandler
            : onSubmitMasterEvaluationHandler
        }
        loading={loadingBtn}
        className="!w-fit"
      >
        ثبت
      </Button>
    </div>
  );
};

export default FormMasterValidation;
