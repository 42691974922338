import React, { useEffect, useState } from "react";

//react router dom
import { useLocation } from "react-router-dom";

//cookies
import { useCookies } from "react-cookie";

//service
import { GetPendingStudent } from "../../service/students";

//component
import TableWrapper from "../../components/common/tableWrapper";
import RequestItem from "../../components/pages/requests/requestItem";
import ContentWrapper from "../../components/common/contentWrapper";

const tableHeader = [
  {
    title: "#",
    style: "col-span-1 text-center",
  },
  {
    title: "نام",
    style: "col-span-2 text-center",
  },
  {
    title: "نام خانوادگی",
    style: "col-span-2 text-center",
  },
  {
    title: "شماره دانشجویی",
    style: "col-span-2 text-center",
  },
  {
    title: "ترم ورود به دانشگاه",
    style: "col-span-2 text-center",
  },
  {
    title: "درخواست",
    style: "col-span-2 text-center",
  },
];

const Request = () => {
  //cookies
  const [{ token }] = useCookies(["token"]);

  //location
  const location = useLocation();

  //data
  const [studentsList, setStudentsList] = useState({
    isLoading: false,
    data: [],
  });

  useEffect(() => {
    httpGetPendingStudentList(location.search.substring(1));
  }, [location]);

  const httpGetPendingStudentList = async (filter = "") => {
    setStudentsList({
      isLoading: true,
    });
    try {
      const response = await GetPendingStudent({ filter, token });

      if (response.status === 200) {
        setStudentsList({
          isLoading: false,
          data: [...response.data.data],
          meta: { ...response.data.meta },
        });
      } else {
        //there is some error come form backend
      }
    } catch (error) {
      console.log("error in get list of pending student : ", error);
    }

    setStudentsList((prevState) => ({
      isLoading: false,
      ...prevState,
    }));
  };

  const genarateList = () => {
    if (studentsList.isLoading === true) {
      return Array(5)
        .fill("emptylist")
        .map((singleArray, index) => (
          <tr key={index} className="grid grid-cols-12 w-full gap-2">
            <td className="col-span-1 w-full h-7 rounded-md animate-pulse bg-gray-400"></td>
            <td className="col-span-2 w-full h-7 rounded-md animate-pulse bg-gray-400"></td>
            <td className="col-span-2 w-full h-7 rounded-md animate-pulse bg-gray-400"></td>
            <td className="col-span-2 w-full h-7 rounded-md animate-pulse bg-gray-400"></td>
            <td className="col-span-2 w-full h-7 rounded-md animate-pulse bg-gray-400"></td>
            <td className="col-span-2 w-full h-7 rounded-md animate-pulse bg-gray-400"></td>
            <td className="col-span-1 w-full h-7 rounded-md animate-pulse bg-gray-400"></td>
          </tr>
        ));
    }

    return studentsList.data?.map((item, index) => (
      <RequestItem key={index} data={item} index={index} />
    ));
  };

  return (
    <ContentWrapper subRoutes={[{ title: "درخواست ها", href: "" }]}>
      {studentsList.data?.length === 0 ? (
        <div className="py-28 w-full shadow-[0_2px_28px_0px_rgba(203,209,232,0.15)] rounded-3xl bg-white">
          <span className="text-[#9FA5BB] font-medium">
            متاسفانه هیچ درخواستی وجود ندارد!
          </span>
        </div>
      ) : (
        <TableWrapper
          minSize={`min-w-[900px]`}
          tableHeader={tableHeader}
          meta={studentsList.meta}
        >
          {genarateList()}
        </TableWrapper>
      )}
    </ContentWrapper>
  );
};

export default Request;
