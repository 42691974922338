import React from "react";

const TeacherInput = ({ className = "", ...rest }) => {
  return (
    <input
      className={`border outline-none rounded-md px-3 py-2 ${className}`}
      {...rest}
    />
  );
};

export default TeacherInput;
