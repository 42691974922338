import { useState, useEffect } from "react";

//cookies
import { useCookies } from "react-cookie";

//component
import ModalWrapper from "../../../common/modalWrapper";
import LoadingModalDetail from "./loadingDetail";

//service
import { GetSingleStudentDetail } from "../../../../service/students";

//svg
import { ReactComponent as RightArrowSvg } from "./../../../../assets/icons/svg/arrow-right-circle.svg";

export default function DetailStudentModal({
  isShowModal,
  onCloseModalHandler,
  id,
}) {
  //cookies
  const [{ token }] = useCookies(["token"]);

  //data
  const [singleStudent, setSingleStudent] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    httpGetSingleStudentData();
  }, []);

  const httpGetSingleStudentData = async () => {
    setIsLoading(true);

    try {
      const response = await GetSingleStudentDetail({ id, token });

      if (response.status === 200) {
        setSingleStudent({
          ...response.data.data,
        });
      } else {
        console.log("error in data status code : ", response.status);
      }
    } catch (error) {
      console.log("error in get single student : ", error);
    }
    setIsLoading(false);
  };

  return (
    <ModalWrapper
      modalClass={"max-h-[90%] overflow-y-auto !p-3 sm:p-6 w-full"}
      isShowedModal={isShowModal}
      onCloseModal={onCloseModalHandler}
    >
      <div className="w-full flex items-start flex-col gap-5 sm:gap-7 sm:p-5">
        <button className="px-5 py-2 rounded-lg text-[#2A3042] font-semibold text-lg bg-[#9FA5BB] bg-opacity-20 border-2 border-[#9FA5BB] border-opacity-20 hover:bg-white duration-200 flex items-center justify-center gap-2">
          <RightArrowSvg />
          بازگشت
        </button>
        {isLoading ? (
          <LoadingModalDetail />
        ) : (
          <>
            <div className="w-full p-5 sm:p-10 shadow-[0_2px_28px_0px_rgba(203,209,232,0.15)] rounded-3xl bg-white flex flex-col items-start gap-10">
              <span className="text-2xl font-semibold">اطلاعات کارآموز</span>
              <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 text-right gap-5">
                <div className="flex flex-wrap">
                  <span className="text-lg font-medium">نام:</span>
                  <span className="text-[#9FA5BB] font-medium mr-2">
                    {singleStudent.first_name}
                  </span>
                </div>
                <div className="flex flex-wrap">
                  <span className="text-lg font-medium">نام خانوادگی:</span>
                  <span className="text-[#9FA5BB] font-medium mr-2">
                    {singleStudent.last_name}
                  </span>
                </div>
                <div className="flex flex-wrap ">
                  <span className="text-lg font-medium">شماره دانشجویی:</span>
                  <span className="text-[#9FA5BB] font-medium mr-2">
                    {singleStudent.student_number}
                  </span>
                </div>
                <div className="flex flex-wrap">
                  <span className="text-lg font-medium">رشته تحصیلی:</span>
                  <span className="text-[#9FA5BB] font-medium mr-2">
                    {singleStudent.faculty}
                  </span>
                </div>
                <div className="flex flex-wrap">
                  <span className="text-lg font-medium">مقطع تحصیلی:</span>
                  <span className="text-[#9FA5BB] font-medium mr-2">
                    {singleStudent.degree}
                  </span>
                </div>
                <div className="flex flex-wrap">
                  <span className="text-lg font-medium">
                    تعداد واحد گذرانده:
                  </span>
                  <span className="text-[#9FA5BB] font-medium mr-2">
                    {singleStudent.passed_units}
                  </span>
                </div>
                <div className="flex flex-wrap">
                  <span className="text-lg font-medium">نیم سال تحصیلی:</span>
                  <span className="text-[#9FA5BB] font-medium mr-2">
                    داده ای نیست
                  </span>
                </div>
                <div className="flex flex-wrap">
                  <span className="text-lg font-medium">سال:</span>
                  <span className="text-[#9FA5BB] font-medium mr-2">
                    {singleStudent.entrance_year}
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full p-5 sm:p-10 shadow-[0_2px_28px_0px_rgba(203,209,232,0.15)] rounded-3xl bg-white flex flex-col items-start gap-10">
              <span className="text-2xl font-semibold">
                مشخصات محل کارآموزی
              </span>
              <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 text-right gap-5">
                <div className="flex flex-wrap">
                  <span className="text-lg font-medium">نام محل:</span>
                  <span className="text-[#9FA5BB] font-medium mr-2">
                    {singleStudent.company.name}
                  </span>
                </div>
                <div className="flex flex-wrap">
                  <span className="text-lg font-medium">نوع محل:</span>
                  <span className="text-[#9FA5BB] font-medium mr-2">
                    {singleStudent.company.type}
                  </span>
                </div>
                <div className="flex flex-wrap">
                  <span className="text-lg font-medium">شماره تلفن:</span>
                  <span className="text-[#9FA5BB] font-medium mr-2">
                    {singleStudent.company.number
                      ? singleStudent.company.number
                      : "داده ای وجود ندارد"}
                  </span>
                </div>
                <div className="flex flex-wrap">
                  <span className="text-lg font-medium">کدپستی:</span>
                  <span className="text-[#9FA5BB] font-medium mr-2">
                    {singleStudent.company.postal_code
                      ? singleStudent.company.postal_code
                      : "داده ای وجود ندارد"}
                  </span>
                </div>
                <div className="flex flex-wrap">
                  <span className="text-lg font-medium">آدرس محل</span>
                  <span className="text-[#9FA5BB] font-medium mr-2">
                    {singleStudent.company.address
                      ? singleStudent.company.address
                      : "داده ای وجود ندارد"}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </ModalWrapper>
  );
}
