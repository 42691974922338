import React, { useEffect, useState } from "react";

//cookies
import { useCookies } from "react-cookie";

//service
import { GetVerfiedStudent } from "../../service/students";
import { Table } from "antd";

//component
import ContentWrapper from "../../components/common/contentWrapper";
import { Link } from "react-router-dom";

const TABLE_HEADER = [
  {
    title: "#",
    dataIndex: "index",
    key: "index",
    render: (_, data, index) => <span>{index + 1}</span>,
  },
  {
    title: "نام",
    dataIndex: "first_name",
    key: "first_name",
  },
  {
    title: "نام خانوادگی",
    dataIndex: "last_name",
    key: "last_name",
  },
  {
    title: "شماره دانشجویی",
    dataIndex: "student_number",
    key: "student_number",
  },
  {
    title: "وضعیت دانشجو",
    dataIndex: "status",
    key: "status",
    render: (_, data) => {
      if (data.status.stage === 1) {
        return "در حال ثبت نام";
      } else if (data.status.stage === 2) {
        return "در حال انجام کارآموزی";
      } else {
        return "اتمام کارآموزی";
      }
    },
  },
  {
    title: "شماره دانشجویی",
    dataIndex: "operation",
    key: "operation",
    render: (_, data, index) => (
      <Link
        to={`/internships/${data.id}`}
        className="font-medium text-yellow-700"
      >
        مشاهده
      </Link>
    ),
  },
];
const InternShips = () => {
  //cookies
  const [{ token }] = useCookies(["token"]);
  const [page, setPage] = useState(1);

  //data
  const [studentsList, setStudentsList] = useState({
    isLoading: false,
    data: [],
  });

  useEffect(() => {
    httpGetVerifiedStudentList(page);
  }, [page]);

  const httpGetVerifiedStudentList = async (page) => {
    setStudentsList({
      isLoading: true,
    });
    try {
      const response = await GetVerfiedStudent({ page, token });

      if (response.status === 200) {
        setStudentsList({
          isLoading: false,
          data: [...response.data.data],
          meta: { ...response.data.meta },
        });
      } else {
        //there is some error come form backend
      }
    } catch (error) {
      console.log("error in get list of verfied student : ", error);
    }

    setStudentsList((prevState) => ({
      isLoading: false,
      ...prevState,
    }));
  };

  return (
    <ContentWrapper subRoutes={[{ title: "کارآموز ها", href: "" }]}>
      <Table
        loading={studentsList.isLoading}
        rowKey={(record) => record.title}
        columns={TABLE_HEADER}
        dataSource={studentsList.data}
        pagination={{
          pageSize: 10, // Set the number of items per page
          total: studentsList.meta?.total_records, // Set the total number of items
          onChange: (page) => {
            setPage(page);
          },
        }}
      />
    </ContentWrapper>
  );
};

export default InternShips;
