import React, { useState } from "react";

//react-router-dom
import { Link, Outlet, useLocation } from "react-router-dom";

//svg
import { ReactComponent as HomeSvg } from "./../../../assets/icons/svg/home-2.svg";
import { ReactComponent as RequestSvg } from "./../../../assets/icons/svg/mirroring-screen.svg";
import { ReactComponent as StudentsSvg } from "./../../../assets/icons/svg/people.svg";
import { ReactComponent as MassagingSvg } from "./../../../assets/icons/svg/sms.svg";
import { ReactComponent as ArrowDown } from "./../../../assets/icons/svg/arrow-down.svg";

//image
import UserTempPic from "./../../../assets/pictures/temp/Ellipse 31.png";
import LogoutModal from "./logoutModal";

const navList = [
  {
    title: "داشبورد",
    icon: <HomeSvg className="w-5" />,
    route: "/dashboard",
  },
  {
    title: "درخواست ها",
    icon: <RequestSvg className="w-5" />,
    route: "/requests",
  },
  {
    title: "کارآموز ها",
    icon: <StudentsSvg className="w-5" />,
    route: "/internships",
  },
];

const Layout = () => {
  const [isShowLogoutModal, setIsShowLogoutModal] = useState(false);
  const location = useLocation();

  return (
    <div>
      <header className="bg-transparent sm:bg-[#2A3042] shadow-none sm:shadow-[0_4px_16px_0px_rgba(79,84,104,0.42)] text-white p-5">
        <div className="flex items-center justify-end sm:justify-between max-w-7xl mx-auto">
          <div className="sm:relative fixed z-50 bottom-0 right-0 left-0 bg-[#2A3042] shadow-[0_4px_16px_0px_rgba(79,84,104,0.42)] sm:shadow-none w-full p-2 sm:p-0 sm:w-fit flex items-center justify-between sm:justify-center gap-2 sm:gap-5 md:gap-10">
            {navList.map((singleNav, index) => (
              <Link
                key={index}
                to={singleNav.route}
                className={`flex items-center justify-center flex-col gap-1 after:block after:duration-200 after:bg-[#D5A419] after:w-0 ${
                  location.pathname.includes(singleNav.route)
                    ? "after:w-full"
                    : "hover:after:w-full"
                } after:h-0.5 after:rounded-md`}
              >
                <span className="flex items-center justify-center text-xs sm:text-base gap-1 sm:gap-3">
                  {singleNav.icon}
                  {singleNav.title}
                </span>
              </Link>
            ))}
          </div>
          <button
            onClick={() => setIsShowLogoutModal(true)}
            className="text-[#292D32] sm:text-white"
          >
            خروج
          </button>
        </div>
      </header>
      <div className="max-w-7xl mx-auto px-5">
        <Outlet />
      </div>

      <LogoutModal
        closeModal={() => setIsShowLogoutModal(false)}
        isShow={isShowLogoutModal}
      />
    </div>
  );
};

export default Layout;
