import React from "react";

//SVG
import { ReactComponent as ArrowDown } from "./../../../assets/icons/svg/arrow-down.svg";
import { Link } from "react-router-dom";

const ContentWrapper = ({ children, subRoutes }) => {
  return (
    <div className="my-16 flex items-center justify-center flex-col">
      <div className="shadow-[0_2px_28px_0px_rgba(203,209,232,0.15)] bg-white p-7 rounded-3xl w-full mb-5">
        <div className="flex items-center justify-start gap-2 flex-wrap">
          <span className="text-[#2A3042]">داشبورد</span>
          {subRoutes?.map((item, index) => (
            <React.Fragment key={index}>
              <ArrowDown className="rotate-90 text-[#4F5468]" />
              <Link to={item.href} className="text-[#9FA5BB]">
                {item.title}
              </Link>
            </React.Fragment>
          ))}
        </div>
      </div>
      {children}
    </div>
  );
};

export default ContentWrapper;
