import React from "react";

//component
import TablePagination from "./pagination";

const TableWrapper = ({ minSize, tableHeader, children, meta }) => {
  const genarateMeta = () => {
    if (meta !== undefined) {
      if (meta.total_records === 0) {
        return <></>;
      } else {
        return <TablePagination meta={meta} />;
      }
    }
  };

  return (
    <div className="overflow-x-auto w-full shadow-[0_2px_28px_0px_rgba(203,209,232,0.15)] bg-white rounded-3xl p-5">
      <div className={`${minSize}`}>
        <table className="flex flex-col w-full p-5">
          <thead className="bg-[#e5f0ff26] p-2 text-[#2A3042] mb-7">
            <tr className="grid grid-cols-12 w-full">
              {tableHeader.map((header, headerIndex) => (
                <th key={headerIndex} className={`${header.style}`}>
                  {header.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="flex flex-col gap-7">{children}</tbody>
        </table>
      </div>
      {genarateMeta()}
    </div>
  );
};

export default TableWrapper;
