import useFetch from "./../hooks/useFetch";

const endPoint = "students";

export async function GetStudentCount({ token }) {
  const apiCall = await useFetch().get(`${endPoint}/count`, {
    headers: {
      Authorization: `Bearer ` + token,
    },
  });

  return apiCall;
}

export async function GetPendingStudent({ filter, token }) {
  const apiCall = await useFetch().get(`${endPoint}/pending?${filter}`, {
    headers: {
      Authorization: `Bearer ` + token,
    },
  });

  return apiCall;
}

export async function ChangeStudentNumber({ token, newStudentNumber }) {
  const apiCall = await useFetch().put(
    `${endPoint}/count`,
    {
      students_count: +newStudentNumber,
    },
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );

  return apiCall;
}

export async function GetVerfiedStudent({ page, token }) {
  const apiCall = await useFetch().get(`${endPoint}/verified?page=${page}`, {
    headers: {
      Authorization: `Bearer ` + token,
    },
  });

  return apiCall;
}

export async function GetSingleStudentDetail({ token, id }) {
  const apiCall = await useFetch().get(`${endPoint}/${id}`, {
    headers: {
      Authorization: `Bearer ` + token,
    },
  });

  return apiCall;
}
