import { useState } from "react";

//react router dom
import { useNavigate } from "react-router-dom";

//cookies
import { useCookies } from "react-cookie";

//component
import ModalWrapper from "../../../common/modalWrapper";

//service
import { RejectStudent } from "../../../../service/requests";

//utils
import Spinner from "../../../utils/spinner";

export default function RejectStudentModal({
  onCloseModalHandler,
  isShowModal,
  id,
  firstName,
  lastName,
  studentNumber,
}) {
  //navigate
  const navigate = useNavigate();

  //cookies
  const [{ token }] = useCookies(["token"]);

  //data
  const [isLoading, setIsLoading] = useState(false);

  const httpRejectStudent = async () => {
    //check if is loading => dont response to backend
    if (isLoading) return;

    setIsLoading(true);
    try {
      const response = await RejectStudent({ token, id });

      if (response.status === 200) {
        //reload this page
        navigate(0);
      } else {
        console.log("status code : ", response.status);
      }
    } catch (error) {
      console.log("error in reject student : ", error);
    }
    setIsLoading(false);
  };

  return (
    <ModalWrapper
      isShowedModal={isShowModal}
      onCloseModal={onCloseModalHandler}
    >
      <div>
        <p className="mb-7">
          آیا از رد دانشجو با نام "{firstName + " " + lastName}" و شماره
          دانشجویی "{studentNumber}" مطمئن هستید ؟
        </p>
        <div className="w-full flex items-center justify-end gap-5">
          <button
            onClick={httpRejectStudent}
            className="px-7 py-1 text-white bg-red-700 border-2 border-red-700 hover:text-red-700 hover:bg-white duration-200 rounded-md"
          >
            {isLoading ? (
              <Spinner
                size="7"
                fillColor="fill-white"
                textColor="text-red-700"
              />
            ) : (
              "حذف"
            )}
          </button>
          <button
            onClick={onCloseModalHandler}
            className="px-7 py-1 text-white bg-yellow-700 border-2 border-yellow-700 hover:text-yellow-700 hover:bg-white duration-200 rounded-md"
          >
            انصراف
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}
