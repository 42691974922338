import useFetch from "./../hooks/useFetch";

const endPoint = "students";

export async function RejectStudent({ token, id }) {
  const apiCall = await useFetch().put(
    `${endPoint}/${id}/unverify`,
    {},
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );

  return apiCall;
}

export async function ConfirmStudent({ token, id }) {
  const apiCall = await useFetch().put(
    `${endPoint}/${id}/verify`,
    {},
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );

  return apiCall;
}
