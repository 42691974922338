import ContentWrapper from "../../../components/common/contentWrapper";
import { useNavigate, useParams } from "react-router-dom";
const SingleInternship = () => {
  const { id } = useParams();
  const navigation = useNavigate();

  return (
    <ContentWrapper
      subRoutes={[
        { title: "کارآموز ها", href: "/internships" },
        { title: "نام کارآموز", href: "" },
      ]}
    >
      <div className="grid md:grid-cols-2 gap-5 shadow-[0_2px_28px_0px_rgba(203,209,232,0.15)] bg-white p-4 sm:p-7 rounded-3xl w-full mb-5">
        <div className="border border-gray-100 shadow-sm rounded-lg p-5">
          <span className="font-semibold">ارزیابی استاد از دانشجو</span>
          <div className="flex flex-col sm:flex-row items-center justify-between pt-5 gap-2">
            <div className="flex items-center gap-x-1">
              <span>وضعیت:</span>
              <span className="font-semibold">انجام شده</span>
            </div>
            <button
              onClick={() => navigation(`/internships/${id}/validation`)}
              className="w-full sm:w-fit px-7 py-1 text-white bg-blue-700 border-2 border-blue-700 hover:text-blue-700 hover:bg-white duration-200 rounded-md"
            >
              مشاهده
            </button>
          </div>
        </div>
        <div className="border border-gray-100 shadow-sm rounded-lg p-5">
          <span className="font-semibold">فرم گزارش پایانی</span>
          <div className="flex flex-col sm:flex-row gap-2 items-center justify-between pt-5">
            <div className="flex items-center gap-x-1">
              <span>وضعیت:</span>
              <span className="font-semibold">انجام شده</span>
            </div>
            <button
              onClick={() => navigation(`/internships/${id}/final-report`)}
              className="w-full sm:w-fit px-7 py-1 text-white bg-blue-700 border-2 border-blue-700 hover:text-blue-700 hover:bg-white duration-200 rounded-md"
            >
              مشاهده
            </button>
          </div>
        </div>
        <div className="border border-gray-100 shadow-sm rounded-lg p-5">
          <span className="font-semibold">گزارش هفتگی</span>
          <div className="flex flex-col sm:flex-row gap-2 items-center justify-between pt-5">
            <div className="flex items-center gap-x-1">
              <span>وضعیت:</span>
              <span className="font-semibold">انجام شده</span>
            </div>
            <button
              onClick={() => navigation(`/internships/${id}/weekly-report`)}
              className="w-full sm:w-fit px-7 py-1 text-white bg-blue-700 border-2 border-blue-700 hover:text-blue-700 hover:bg-white duration-200 rounded-md"
            >
              مشاهده
            </button>
          </div>
        </div>
      </div>
    </ContentWrapper>
  );
};

export default SingleInternship;
