import { useState, useEffect } from "react";

//moment
import moment from "moment-jalaali";

//cookies
import { useCookies } from "react-cookie";

//service
import { GetStudentCount } from "../../service/students";

//component
import ChangeStudentModal from "../../components/pages/dashboard/changeStudentModal";

const Dashboard = () => {
  moment.loadPersian({ usePersianDigits: true });

  //cookies
  const [{ token }] = useCookies(["token"]);

  //data
  const [isLoading, setIsLoading] = useState(false);
  const [availableStudent, setAvailableStudent] = useState({});

  //data , modal
  const [isShowChangeStudentModal, setIsShowStudentModal] = useState(false);

  useEffect(() => {
    httpGetAvailableStudent();
  }, []);

  const httpGetAvailableStudent = async () => {
    setIsLoading(true);
    try {
      const response = await GetStudentCount({ token });

      //check response status
      if (response.status === 200) {
        setAvailableStudent({
          ...response.data,
        });
      } else {
        // there is error in data that we get
      }
    } catch (error) {
      console.log("error in get available student : ", error);
    }
    setIsLoading(false);
  };

  return (
    <div className="mt-5 w-full">
      <p className="text-2xl font-medium text-right">داشبورد</p>
      <div>
        {isLoading === false ? (
          availableStudent.term && (
            <div className="sm:w-[320px] sm:mx-auto w-full shadow-md rounded-lg flex flex-col items-start gap-3 p-3">
              <div className="space-y-1">
                <p className="text-lg font-medium">نام ترم</p>
                <p className="text-gray-400">{availableStudent.term.name}</p>
              </div>
              <div className="space-y-1">
                <p className="text-lg font-medium">تاریخ شروع</p>
                <p className="text-gray-400">
                  {moment(availableStudent.term.start_date).format(
                    "jYYYY/jMM/jDD"
                  )}
                </p>
              </div>
              <div className="space-y-1">
                <p className="text-lg font-medium">تاریخ پایان</p>
                <p className="text-gray-400">
                  {moment(availableStudent.term.end_date).format(
                    "jYYYY/jMM/jDD"
                  )}
                </p>
              </div>
              <div className="space-y-1">
                <p className="text-lg font-medium">ظرفیت دانشجویان</p>
                <p className="text-gray-400 text-right">
                  {availableStudent.min}
                </p>
              </div>
              <button
                onClick={() => setIsShowStudentModal(true)}
                className="px-4 py-1 rounded-md bg-[#D5A419] text-white border-2 border-[#D5A419] hover:bg-white hover:text-[#D5A419] duration-200 "
              >
                تغییر ظرفیت دانشجویان
              </button>
            </div>
          )
        ) : (
          <div className="sm:w-[320px] sm:mx-auto w-full shadow-md rounded-lg flex flex-col items-start gap-3 p-3">
            <div className="space-y-2 mb-3 w-full">
              <p className="w-1/3 h-7 bg-gray-400 animate-pulse rounded-md"></p>
              <p className="w-full h-7 bg-gray-400 animate-pulse rounded-md"></p>
            </div>
            <div className="space-y-2 mb-3 w-full">
              <p className="w-1/3 h-7 bg-gray-400 animate-pulse rounded-md"></p>
              <p className="w-full h-7 bg-gray-400 animate-pulse rounded-md"></p>
            </div>
            <div className="space-y-2 mb-3 w-full">
              <p className="w-1/3 h-7 bg-gray-400 animate-pulse rounded-md"></p>
              <p className="w-full h-7 bg-gray-400 animate-pulse rounded-md"></p>
            </div>
            <div className="space-y-2 mb-3 w-full">
              <p className="w-1/3 h-7 bg-gray-400 animate-pulse rounded-md"></p>
              <p className="w-full h-7 bg-gray-400 animate-pulse rounded-md"></p>
            </div>
          </div>
        )}
      </div>

      {isShowChangeStudentModal && (
        <ChangeStudentModal
          isShowModal={isShowChangeStudentModal}
          onCloseModalHandler={() => setIsShowStudentModal(false)}
          prevStudentNumber={availableStudent.min}
        />
      )}
    </div>
  );
};

export default Dashboard;
