import { useCookies } from "react-cookie";
import ModalWrapper from "../../modalWrapper";

const LogoutModal = ({ isShow, closeModal }) => {
  const [, , removeCookie] = useCookies(["token"]);

  const onLogoutHandler = () => {
    //clear token
    removeCookie("token");

    //go to authPage
    window.location.replace(window.location.origin);
  };

  return (
    <ModalWrapper isShowedModal={isShow} onCloseModal={closeModal}>
      <span className="text-xl">آیا می‌خواهید از سامانه خارج شوید ؟</span>
      <div className="flex items-center justify-center gap-x-2 mt-5">
        <button
          onClick={onLogoutHandler}
          className="px-3 py-1.5 rounded-md bg-red-700 hover:bg-red-900 duration-300 text-white"
        >
          خروج
        </button>
        <button
          onClick={closeModal}
          className="px-3 py-1.5 rounded-md bg-gray-700 hover:bg-gray-900 duration-300 text-white"
        >
          انصراف
        </button>
      </div>
    </ModalWrapper>
  );
};

export default LogoutModal;
