import React, { useEffect } from "react";

//react-router-dom
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

//component
import Layout from "./components/common/layout";

import Request from "./pages/requests";
import Dashboard from "./pages/dashboard";
import InternShips from "./pages/internships";
import SingleInternship from "./pages/internships/single-internship";
import Validation from "./pages/internships/single-internship/validation";
import FinalReport from "./pages/internships/single-internship/final-report";
import WeeklyReport from "./pages/internships/single-internship/weekly-report";

import "./App.css";
import Auth from "./pages/auth";
import { useCookies } from "react-cookie";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [{ token }] = useCookies(["token"]);

  //use useEffect to check the route
  useEffect(() => {
    //check the pathname
    if (location.pathname === "/" && token !== undefined) {
      //path name is / => re route to the dashboard
      navigate("/dashboard");
    } else if (location.pathname !== "/" && token === undefined) {
      window.location.replace(window.location.origin);
    }
  }, [location.pathname, navigate, token]);

  return (
    <div className="App">
      <Routes>
        <Route element={<Auth />} path="/" />

        <Route path="/" element={<Layout />}>
          <Route element={<Dashboard />} path="/dashboard" />
          <Route element={<Request />} path="/requests" />
          <Route element={<InternShips />} path="/internships" />
          <Route element={<SingleInternship />} path="/internships/:id" />
          <Route element={<Validation />} path="/internships/:id/validation" />

          <Route
            element={<FinalReport />}
            path="/internships/:id/final-report"
          />
          <Route
            element={<WeeklyReport />}
            path="/internships/:id/weekly-report"
          />
        </Route>
      </Routes>

      <ToastContainer />
    </div>
  );
}

export default App;
