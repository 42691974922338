import { useEffect, useState } from "react";
import ContentWrapper from "../../../../components/common/contentWrapper";
import { Table } from "antd";
import { GetStudentValidation } from "../../../../service/validation";
//cookies
import { useCookies } from "react-cookie";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IoCheckmark } from "react-icons/io5";
import moment from "moment-jalaali";
import FormMasterValidation from "../../../../components/pages/internships/form-master-validation";

const TABLE_HEADER = [
  {
    title: "#",
    dataIndex: "index",
    key: "index",
    render: (_, data, index) => <span>{index + 1}</span>,
  },
  {
    title: "عنوان",
    dataIndex: "name",
    key: "name",
    render: (value) => <p className="max-w-sm whitespace-pre-wrap">{value}</p>,
  },
  {
    title: "عالی ( 2.5 )",
    dataIndex: "value",
    key: "value_1",
    render: (value) =>
      Number(value) === 4 && <IoCheckmark size={20} className="mx-auto" />,
  },
  {
    title: "خوب ( 2 )",
    dataIndex: "value",
    key: "value_2",
    render: (value) =>
      Number(value) === 3 && <IoCheckmark size={20} className="mx-auto" />,
  },
  {
    title: "متوسط ( 1.5 )",
    dataIndex: "value",
    key: "value_3",
    render: (value) =>
      Number(value) === 2 && <IoCheckmark size={20} className="mx-auto" />,
  },
  {
    title: "ضعیف ( 0.5 )",
    dataIndex: "value",
    key: "value_4",
    render: (value) =>
      Number(value) === 1 && <IoCheckmark size={20} className="mx-auto" />,
  },
];
const Validation = () => {
  const [studentsList, setStudentsList] = useState({ isLoading: true });
  const { id } = useParams();
  const navigate = useNavigate();

  //cookies
  const [{ token }] = useCookies(["token"]);

  useEffect(() => {
    httpGetStudentValidation();
  }, []);

  const httpGetStudentValidation = async () => {
    setStudentsList({
      isLoading: true,
    });
    try {
      const response = await GetStudentValidation({ token, id });
      if (response.status === 200) {
        setStudentsList({
          isLoading: false,
          data: response.data.data,
        });
      } else if (response.status === 404 || response.status === 400) {
        toast.error("دریافت اطلاعات برای این دانشجو با مشکل مواجه شد");
        navigate(`/internships/${id}`);
      } else {
        toast.error("دریافت اطلاعات برای این دانشجو با مشکل مواجه شد");
      }
    } catch (error) {
      console.log("error in get student validation : ", error);
    }

    setStudentsList((prevState) => ({
      isLoading: false,
      ...prevState,
    }));
  };

  return (
    <ContentWrapper
      subRoutes={[
        { title: "کارآموز ها", href: "/internships" },
        { title: "نام کارآموز", href: `/internships/${id}` },
        { title: "ارزیابی دانشجو", href: "" },
      ]}
    >
      {studentsList.isLoading ? (
        <div role="status">
          <svg
            aria-hidden="true"
            className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        </div>
      ) : (
        <>
          <div className="flex flex-col mb-16 gap-5 shadow-[0_2px_28px_0px_rgba(203,209,232,0.15)] bg-white p-7 rounded-3xl w-full">
            <p className="text-[#5F5F61] text-right">
              کارآموز{" "}
              <span className="text-black font-medium">
                {studentsList.data.student.first_name +
                  " " +
                  studentsList.data.student.last_name}
              </span>{" "}
              با شماره دانشجویی
              <span className="text-black font-medium">
                {" "}
                {studentsList.data.student.student_number}
              </span>
              در دانشکده
              <span className="text-black font-medium">
                {" "}
                {studentsList.data.student.faculty_name}
              </span>{" "}
              در مقطع تحصیلی کارشناسی و در شرکت
              <span className="text-black font-medium">
                {" "}
                {studentsList.data.company.name}
              </span>{" "}
              که تاریخ شروع کارآموزی :
              <span className="text-black font-medium">
                {" "}
                {moment(studentsList.data.student.internship_start_date).format(
                  "jYYYY/jMM/jDD"
                )}
              </span>{" "}
              بود، برای تایید فرستاده شده است،که اطلاعات بیشتر به شرح ذیل است:
            </p>

            <div className="w-full grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 mb-5">
              <div className="flex items-start justify-start gap-3">
                <span className="text-[#5F5F61]">نام شرکت:</span>
                <span className="text-[#222124] font-medium">
                  {studentsList.data.company.name}
                </span>
              </div>
              <div className="flex items-start justify-start gap-3">
                <span className="text-[#5F5F61]">نوع شرکت:</span>
                <span className="text-[#222124] font-medium">
                  {studentsList.data.company.type}
                </span>
              </div>
              <div className="flex items-start justify-start gap-3">
                <span className="text-[#5F5F61]">شماره تماس:</span>
                <span className="text-[#222124] font-medium">
                  {studentsList.data.company.phone_number}
                </span>
              </div>
              <div className="flex items-start justify-start gap-3">
                <span className="text-[#5F5F61]">کد پستی:</span>
                <span className="text-[#222124] font-medium">
                  {studentsList.data.company.postal_code}
                </span>
              </div>
              <div className="sm:col-span-2 flex items-start justify-start gap-3">
                <span className="text-[#5F5F61]">آدرس:</span>
                <span className="text-[#222124] font-medium">
                  {studentsList.data.company.address}
                </span>
              </div>
            </div>
            <div className="w-full grid md:grid-cols-2 lg:grid-cols-3 gap-5">
              <div className="flex items-start justify-start gap-3">
                <span className="text-[#5F5F61]">
                  نام و نام خانوادگی سرپرست کارآموزی:
                </span>
                <span className="text-[#222124] font-medium">
                  {studentsList.data.industry_supervisor.full_name}
                </span>
              </div>
              <div className="flex items-start justify-start gap-3">
                <span className="text-[#5F5F61]">سمت: </span>
                <span className="text-[#222124] font-medium">
                  {studentsList.data.industry_supervisor.position}
                </span>
              </div>
              <div className="flex items-start justify-start gap-3">
                <span className="text-[#5F5F61]">تاریخ شروع کارآموزی:</span>
                <span className="text-[#222124] font-medium">
                  {moment(
                    studentsList.data.student.internship_start_date
                  ).format("jYYYY/jMM/jDD")}
                </span>
              </div>
            </div>
          </div>

          <div className="shadow-[0_2px_28px_0px_rgba(203,209,232,0.15)] bg-white p-7 rounded-3xl w-full flex flex-col items-start gap-7 mb-16">
            <span className="text-lg font-semibold text-[#101114]">
              ارزشیابی نهایی کارآموزی{" "}
            </span>
            <Table
              rowKey={(record) => record.title}
              columns={TABLE_HEADER}
              dataSource={studentsList.data.student_evaluations}
              pagination={false}
            />
            <div className="flex items-center justify-start gap-x-2 text-base">
              <span className="font-semibold">
                میانگین ارزشیابی سرپرست کارآموزی در صنعت:{" "}
              </span>
              <span>{studentsList.data.total_grade}</span>
            </div>
          </div>

          <div className="shadow-[0_2px_28px_0px_rgba(203,209,232,0.15)] bg-white p-7 rounded-3xl w-full flex flex-col items-start gap-7">
            <span className="text-lg font-semibold text-[#101114]">
              ارزیابی استاد از دانشجو
            </span>
            <FormMasterValidation token={token} studentId={id} />
          </div>
        </>
      )}
    </ContentWrapper>
  );
};

export default Validation;
