export default function LoadingModalDetail() {
  return (
    <>
      <div className="w-full p-5 sm:p-10 shadow-[0_2px_28px_0px_rgba(203,209,232,0.15)] rounded-3xl bg-white flex flex-col items-start gap-10">
        <span className="text-2xl font-semibold">اطلاعات کارآموز</span>
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5">
          <div className="flex w-full gap-3">
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
          </div>
          <div className="flex w-full gap-3">
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
          </div>
          <div className="flex w-full gap-3">
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
          </div>
          <div className="flex w-full gap-3">
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
          </div>
          <div className="flex w-full gap-3">
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
          </div>
          <div className="flex w-full gap-3">
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
          </div>
          <div className="flex w-full gap-3">
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
          </div>
          <div className="flex w-full gap-3">
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
          </div>
        </div>
      </div>
      <div className="w-full p-5 sm:p-10 shadow-[0_2px_28px_0px_rgba(203,209,232,0.15)] rounded-3xl bg-white flex flex-col items-start gap-10">
        <span className="text-2xl font-semibold">مشخصات محل کارآموزی</span>
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 text-right gap-5">
          <div className="flex w-full gap-3">
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
          </div>
          <div className="flex w-full gap-3">
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
          </div>
          <div className="flex w-full gap-3">
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
          </div>
          <div className="flex w-full gap-3">
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
          </div>
          <div className="flex w-full gap-3">
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
          </div>
          <div className="flex w-full gap-3">
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
            <span className="w-full bg-gray-400 h-7 rounded-md animate-pulse"></span>
          </div>
        </div>
      </div>
    </>
  );
}
