import useFetch from "./../hooks/useFetch";

const endPoint = "students";

export async function GetWeeklyReportsList({ token, id, page }) {
  const apiCall = await useFetch().get(
    `${endPoint}/forms/weekly_reports?student_id=${id}&page=${page}`,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );

  return apiCall;
}

export async function VerifyWeeklyReport({ token, id }) {
  const apiCall = await useFetch().get(
    `${endPoint}/weekly_reports/verify/${id}`,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );

  return apiCall;
}
